<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.workOrderCode" placeholder="请输入工单号" />
            <Select
                v-model:value="params.teamCode"
                :options="teamOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择生产班组"
            />
            <Select
                v-model:value="params.creatorId"
                :options="submitUserOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择提交人"
            />
            <Select
                v-model:value="params.stepCode"
                :options="processOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择工序"
            />
            <a-range-picker v-model:value="date" valueFormat="YYYY-MM-DD" @change="changeDate" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { apiJCWorkReportIndexData } from "@/api";
export default defineComponent({
    props: ["eventLevelOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            submitUserOptions: [],
            teamOptions: [],
            processOptions: [],
            date: [],
            params: {
                workOrderCode: undefined,
                teamCode: undefined,
                creatorId: undefined,
                stepCode: undefined,
                productionTimeFrom: "",
                productionTimeTo: "",
            },
        });

        const reset = () => {
            state.params.workOrderCode = undefined;
            state.params.teamCode = undefined;
            state.params.creatorId = undefined;
            state.params.stepCode = undefined;
            state.params.productionTimeFrom = "";
            state.params.productionTimeTo = "";
            state.date = [];
            search();
        };

        const changeDate = (_, dateStrings) => {
            let [start, end] = dateStrings;
            state.params.productionTimeFrom = start ? `${start} 00:00:00` : "";
            state.params.productionTimeTo = end ? `${end} 23:59:59` : "";
        };

        const search = () => {
            state.params.workOrderCode = state.params.workOrderCode?.trim();
            emit("search", state.params);
        };

        search();

        const getIndexData = async () => {
            let res = await apiJCWorkReportIndexData();
            if (res.status === "SUCCESS") {
                state.submitUserOptions = res?.data?.creatorIdAndNameCandidate;
                state.teamOptions = res?.data?.teamCodeAndNameCandidate;
                state.processOptions = res?.data?.stepCodeAndNameCandidate;
            }
        };
        getIndexData();

        return {
            ...toRefs(state),
            search,
            reset,
            changeDate,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
